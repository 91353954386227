<div class="container">
  <div class="row">
    <div class="card_container col-12">
      <div class="card m-3">
        <h5 class="card-header header-txt">PCB Details</h5>
        <div class="card-body">
          <form method="post" name="myForm" #myForm="ngForm" (ngSubmit)="savepcbrequestdata()">
            <div class="form-group ">
              <label>Base Material</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="FR-4" value="FR-4"
                    [(ngModel)]="Base_Material">
                  <label class="form-check-label" for="inlineRadio1">FR-4</label>
                </div>
              </div>
            </div>
            <div class="form-group ">
              <label>Layers</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Layers" type="radio" name="inlineRadioOptions1" id="1"
                    value="1">
                  <label class="form-check-label" for="inlineRadio1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Layers" type="radio" name="inlineRadioOptions1" id="2"
                    value="2">
                  <label class="form-check-label" for="inlineRadio2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Layers" type="radio" name="inlineRadioOptions1" id="4"
                    value="4">
                  <label class="form-check-label" for="inlineRadio3">4</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>PCB Qty</label>
              <input type="number" [(ngModel)]="PCB_Qty" name="PCB Qty" class="form-control" />
            </div>
            <!-- <div class="form-group">
              <label>Delivery Format</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                    value="option1">
                  <label class="form-check-label" for="inlineRadio1">Single PCB</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                    value="option1">
                  <label class="form-check-label" for="inlineRadio1">Panel by Robu</label>
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <label>PCB Thickness</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Thickness" type="radio" name="inlineRadioOptions2"
                    id="0.4" value="0.4">
                  <label class="form-check-label" for="inlineRadio1">0.4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Thickness" type="radio" name="inlineRadioOptions2"
                    id="0.6" value="0.6">
                  <label class="form-check-label" for="inlineRadio2">0.6</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Thickness" type="radio" name="inlineRadioOptions2"
                    id="0.8" value="0.8">
                  <label class="form-check-label" for="inlineRadio3">0.8</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Thickness" type="radio" name="inlineRadioOptions2"
                    id="1.0" value="1.0">
                  <label class="form-check-label" for="inlineRadio1">1.0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Thickness" type="radio" name="inlineRadioOptions2"
                    id="1.2" value="1.2">
                  <label class="form-check-label" for="inlineRadio2">1.2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Thickness" type="radio" name="inlineRadioOptions2"
                    id="1.6" value="1.6">
                  <label class="form-check-label" for="inlineRadio3">1.6</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Thickness" type="radio" name="inlineRadioOptions2"
                    id="2.0" value="2.0">
                  <label class="form-check-label" for="inlineRadio3">2.0</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>PCB Color</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Color" type="radio" name="inlineRadioOptions3"
                    id="inlineRadio1" value="option1">
                  <label class="form-check-label" for="inlineRadio1">Green</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Color" type="radio" name="inlineRadioOptions3"
                    id="Red" value="Red">
                  <label class="form-check-label" for="inlineRadio2">Red</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Color" type="radio" name="inlineRadioOptions3"
                    id="Yellow" value="Yellow">
                  <label class="form-check-label" for="inlineRadio3">Yellow</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Color" type="radio" name="inlineRadioOptions3"
                    id="Blue" value="Blue">
                  <label class="form-check-label" for="inlineRadio1">Blue</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Color" type="radio" name="inlineRadioOptions3"
                    id="White" value="White">
                  <label class="form-check-label" for="inlineRadio2">White</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="PCB_Color" type="radio" name="inlineRadioOptions3"
                    id="Black" value="Black">
                  <label class="form-check-label" for="inlineRadio3">Black</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Surface finish</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Surface_finish" type="radio" name="inlineRadioOptions4"
                    id="HASL(with lead)" value="HASL(with lead)">
                  <label class="form-check-label" for="inlineRadio1">HASL(with lead)</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Surface_finish" type="radio" name="inlineRadioOptions4"
                    id="Leadfree HASL-RoHS" value="Leadfree HASL-RoHS">
                  <label class="form-check-label" for="inlineRadio2"> Leadfree HASL-RoHS </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Surface_finish" type="radio" name="inlineRadioOptions4"
                    id="ENIG-RoHS" value="ENIG-RoHS">
                  <label class="form-check-label" for="inlineRadio3">ENIG-RoHS</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Outer Copper Weight</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Outer_Copper_Weight" type="radio"
                    name="inlineRadioOptions5" id="1 oz" value="1 oz">
                  <label class="form-check-label" for="inlineRadio1">1 oz</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Outer_Copper_Weight" type="radio"
                    name="inlineRadioOptions5" id="2 oz" value="2 oz">
                  <label class="form-check-label" for="inlineRadio2">2 oz</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" [(ngModel)]="Outer_Copper_Weight" type="radio"
                    name="inlineRadioOptions5" id="3 oz" value="3 oz">
                  <label class="form-check-label" for="inlineRadio2">3 oz</label>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <label>Remove Order Number</label>
            </div>
            <div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                  value="option1">
                <label class="form-check-label" for="inlineRadio1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                  value="option2">
                <label class="form-check-label" for="inlineRadio2">No</label>
              </div>
            </div> -->
            <div class="form-group">
              <label>Name</label>
              <input name="name" id="name" type="name" [(ngModel)]="Name_cust" class="form-control" />
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <input type="number" [(ngModel)]="mobileno" name="phone" id="phone" class="form-control"
                pattern="[0-9]{10}" />
            </div>
            <div
              *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
              class="text-danger">Invalid phone number (10 digits)</div>
            <div class="form-group">
              <label>Email</label>
              <input name="email" id="email" type="email" [(ngModel)]="emailaddress" class="form-control" required
                email />
            </div>
            <div
              *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
              class="text-danger">Invalid email</div>
            <div class="form-group">
              <label>File Upload</label>
              <p>Consider uploading gerber zip file for a more accurate quote</p>
              <input type="file" [(ngModel)]="PCB_file" (change)="onFileChange($event)" class="form-control" />
            </div>
            <div class="form-group">
              <label>PCB Remark</label>
              <div>
                <div class="mb-3">
                  <textarea class="form-control" name="email" id="email" type="email" [(ngModel)]="Remarks"
                    placeholder="Leave a remark if you have any request" rows="3"></textarea>
                </div>
              </div>
            </div>
            <div class="text-center mt-3">
              <button class="btn btn-primary mr-1">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>